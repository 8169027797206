  /* Thatcher Hotel Showcase */
  .thatcher-showcase {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 5vw auto;
    width: 90vw;
    align-items: center;
  }
  
  .thatcher-card {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .thatcher-card.left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .thatcher-image {
    width: 100%;
    border-radius: 8px;
  }
  
  .thatcher-title {
    font-size: 2rem;
    margin-top: 10px;
    font-weight: bold;
    font-family: "Oswald", serif;
    text-decoration: none;
  }
  
  
  .thatcher-visit-link a {
    text-decoration: none;
    font-family: "Oswald", serif;
    color: black;
    font-size: 2rem;
  }
  
  .thatcher-visit-link a:hover {
    border-bottom: 2px solid coral;
  }
  
  .thatcher-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    text-decoration: none;
  }
  
  .thatcher-card.placeholder {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
  }
  
  .website-preview {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
  
  /* Thatcher Gallery - Below the Main Cards */
  .thatcher-gallery {
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
    gap: 15px;
    margin-top: 10px;
     /* stretches closer to screen edges: */
    width: 98vw;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap; 
  }
  
  /* Individual Image Cards */
  .gallery-card {
    flex: 1 1 23%; /* makes them grow/shrink evenly */
    min-width: 300px; 
    background: #f8f8f8;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Images Inside Gallery Cards */
  .gallery-image {
    width: 100%; 
    height: auto;
    border-radius: 8px;
  }

/* Large Thatcher Hotel Card */
.large-thatcher-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

/* Large Thatcher Hotel video of the carriage house card */
  .brand-video-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .large-thatcher-card-video {
    flex: 1;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    position: relative; /* ➡️ needed for the overlay */
    display: flex;
    justify-content: center;
  }
  
  .large-card-iframe-video iframe {
    width: 100%;
    height: auto;
    max-width: 640px; /* To ensure it doesn't stretch too large */
    border: none;
    border-radius: 8px;
  }
  
  /* Responsive adjustment for smaller screens */
  @media (max-width: 1024px) {
    .large-thatcher-card-video {
      max-width: 100%; /* Makes the container flexible */
      padding: 15px;
      flex-direction: column;  /* Stack vertically on smaller screens */
      text-align: center;
    }
  
    .large-card-iframe-video iframe {
      width: 100%; /* Allow the iframe to be responsive */
      max-width: none; /* Allow the iframe to adjust its size freely */
      height: auto;
    }
  }
  
  @media (max-width: 720px) {
    .large-thatcher-card-video {
      max-width: 100%;  /* Makes the video container take full width */
      padding: 10px;
    }
  
    .large-card-iframe-video iframe {
      width: 100%;
      height: auto;
      max-width: 100%; /* Ensure iframe takes full width */
    }
  }
  
  
  .video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 53%;
    display: flex;
    justify-content: center;
    align-items: center;
     /* Semi-transparent background: */
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    text-align: center;
    border-radius: 0;
    padding: 1px;
    z-index: 10; /* overlay is on top */
  }
  
  .large-card-iframe-video iframe {
    width: 640px;
    height: 564px;
    border: none;
    border-radius: 8px;
  }
  
  /* To style the text with a black font and white outline: */
  .video-overlay h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* black shadow for better contrast */
  }
  
  
  /* flexbox layout to center the card in the viewport */
  .brand-container {
    flex: 1;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 71vh;
    margin: 0; 
  }
  
  /* Styling for the .brand-card */
  .brand-card {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 300px; 
    width: 71%; 
    box-sizing: border-box; 
  }
  
  /* Styling for the image inside the card */
  .brand-card img {
    width: 100%; 
    max-width: 300px; 
    /* to maintain aspect ratio: */
    height: auto; 
    /* to remove inline-block space below the image: */
    display: block; 
    margin: 0 auto; /* ➡️ centers the image horizontally */
  }

  .additional-works {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Bodoni Moda", serif;
    letter-spacing: .2rem;
    font-weight: 500;
    text-shadow: h-offset v-offset blur-radius color;
    background: #181717;
    color: white;
    padding-top: .3rem;
    padding-bottom: .5rem;
    margin-top: 20px;
  }

  /* additional works cards */
  .additional-works-showcase {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .works-type {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .types-img {
    width: 100%;
    border-radius: 8px;
  }
  
  .works-type-title {
    font-size: 2rem;
    margin-top: 10px;
    font-weight: bold;
    font-family: "Oswald", serif;
    text-decoration: none;
    letter-spacing: .2rem;
  }

.install {
  max-width: 300px;
}

.repairs {
  max-width: 280px;
}

.inlay {
  max-width: 200px;
}

.stairs {
  max-width: 250px;
  max-height: 250px;
}



  /* Media Query for smaller screens (Tablet or Mobile) */
@media (max-width: 1024px) {
    .large-thatcher-card-video {
        flex-direction: column;  /* ➡️ stack content vertically */
        text-align: center;
    }

    .additional-works-showcase {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .works-type {
      margin-bottom: 20px;
    }
}

/* For very small screens (Mobile) */
@media (max-width: 720px) {
    .brand-video-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .large-thatcher-card-video {
        padding: 15px; /* ➡️ reduces padding on small screens */
    }

    .video-overlay {
      height: 71%;
    }
}
  
  .large-card-text {
    flex: 1;
    padding-left: 10px;
    font-family: "Oswald", serif;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  .large-video-text {
    flex: 2;
    padding: 20px;
    font-family: "Oswald", serif;
    font-weight: 100;
    font-size: 1.5rem;
    text-align: center;
  }
  
  /* Iframe Section */
  .large-card-iframe {
    flex: 2;
    padding: 10px;
  }
  
  .large-card-iframe-video {
    flex: 1;
  }
  
  
  .accommodations-iframe {
    width: 100%;
    height: 400px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

/* Responsive Adjustments for part of thatcher gallery */
@media (max-width: 1024px) {
    .large-thatcher-card {
        flex-direction: column;
        text-align: center;
        width: 90%;
    }
  
    .large-card-text {
        padding-bottom: 15px;
    }
  
    .accommodations-iframe {
        height: 300px;
    }
  }
  
  
  
  /* 📱 Mobile & Tablet (Max 700px) */
  @media (max-width: 720px) {
    .image-overlay {
      background: none;
    }
    .hero {
      height: 100vh;
      justify-content: flex-start;
    }
  
  }
  
  
  /* Thatcher Hotel Showcase - Stack vertically on smaller screens */
  @media (max-width: 1024px) {
    .thatcher-showcase {
      display: block;
      width: 100%;
      margin: 0;
      padding: 20px 0;
    }
  
    .thatcher-card.left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  
    .thatcher-card {
      margin-bottom: 20px;
      padding: 15px;
    }
  
    .thatcher-title {
      font-size: 1.6rem;
    }
  
    .thatcher-description {
      font-size: 1rem; 
      line-height: 1.5;
      color: #333;
      text-decoration: none;
    }
  
    .thatcher-preview iframe {
      width: 100%;
      height: 300px; 
    }
  
    .large-card-iframe {
      width: 100%;
    }
  
    .thatcher-visit-link a {
      font-size: 1.4rem;
    }
  }
  
  /* For mobile screens (Max-width 720px) */
  @media (max-width: 720px) {
    .thatcher-card.left {
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  
    .thatcher-description {
      font-size: 1rem; 
      line-height: 1.4;
      padding: 0 10px;
    }
  
    .thatcher-preview iframe {
      height: 300px; 
    }
  
    .thatcher-title {
      font-size: 1.5rem;
    }
  
    .thatcher-visit-link a {
      font-size: 1.2rem;
    }
  }
  
/* Basic styling for the popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Dark background to emphasize the popup */
  z-index: 1000; /* Ensure it's on top */
}

.popup-content {
  background: white;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  width: 100%;
}

.popup-close {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 2rem;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
}

.popup-close:hover {
  background: none;
  font-size: 7.1rem;
  color: rgb(249, 8, 44);
}
.popup h2 {
  font-size: 1.5rem;
  color: black;
  font-family: 'Oswald', serif;
  margin-top: 44px;
}

/* Ensure modal content is centered on the page */
.popup-content h2 {
  font-size: 1.2rem;
}

@media (max-width: 1000px) {
  .popup-close:hover {
    font-size: 4.4rem;
  }
}
/* Optional - Animations or Effects */
@media (max-width: 720px) {
  .popup-content {
    padding: 20px;
  }

  .popup-close {
    font-size: 1.5rem;
  }

  .popup h2 {
    font-size: 1rem;
  }
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  padding: 2vw;
}

.popup-content {
  background: white;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  max-width: 90vw;
  width: 90%;
  height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* to stack vertically by jobs*/
  align-items: center;
}

.gallery-grid {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 100%;
}

.gallery-card {
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 1200px;
}

.gallery-images {
  display: flex;
  flex-wrap: wrap; 
  gap: 15px;
  justify-content: center;
  width: 100%;
}

.gallery-image {
  width: auto;
  max-width: 250px;
  border-radius: 8px;
  height: auto;
  object-fit: contain;
}

.gallery-caption {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

/* 🔻 Responsive for mobile screens (Stack images inside job) */
@media (max-width: 1024px) {
  .gallery-images {
    flex-direction: column; /* Stacks images vertically */
    align-items: center;
  }

  .gallery-image {
    max-width: 100%; /* Full width on smaller screens */
  }
}

@media (max-width: 720px) {
  .gallery-grid {
    flex-direction: column;
  }
}
