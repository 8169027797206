/* About Section Container */
.about-container {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background: url("./../../../public/assets/Thatcher_3_Library.jpg") no-repeat center center/cover;
    background-attachment: fixed;
}

/* Background Overlay for Text Readability */
.background-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay for text visibility */
}

/* About Text Container */
.about-words-container {
    width: 90%;
    max-width: 1200px;
    background: rgba(250, 237, 219, 0.89);
    padding: 20px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #333;
    border-radius: 6.2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px; 
    margin-top: 80px;
    margin-left: 1px;
    font-family: "Cutive Mono", serif;
    font-size: 1.1rem;
}

/* Portrait Image Styling */
.portrait-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    padding-left: 0;
}

.portrait {
    width: 15vw;
    height: 15vw;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 0;
}

/* Large Screen (Wide) Layout */
@media (min-width: 1000px) {
    .about-words-container {
        flex-direction: row; /* Arrange text and image horizontally */
        justify-content: space-between;
        align-items: center;
    }

    .portrait-container {
        flex: 1;
        display: block;
        margin-left: 20px;
    }

    .text-container {
        flex: 2;
    }
}

/* Mobile and Small Screen Layout */
@media (max-width: 1000px) {
    .about-words-container {
        flex-direction: column;
        align-items: center;
    }

    .portrait-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
