.fair-use-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.fair-use-card {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Oswald", serif;
    font-size: 1rem;
    font-weight: bold;
    transition: transform 0.2s ease-in-out;
}

.fair-use-card:hover {
    transform: scale(1.05);
}

.fair-use-icon {
    color: black; 
    filter: grayscale(100%);
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

/* Modal Content */
.modal-content {
    background: white;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    font-family: "Oswald", serif;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.close-button:hover {
    color: coral;
}
