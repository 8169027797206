.image-bottom-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100vw;
    min-height: 11vh; 
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

/* Footer Styles */

.footer-container {
  position: relative;
}

.footer-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px; /* Controls shadow thickness */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
}

.landing-footer {
  position: relative;
  width: 100vw;
  height: 40vh;
  overflow: hidden;
  margin-top: 50px;
}

.footer-image {
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  object-position: center bottom;
  display: block;
}

.footer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

/* Style for the footer text and its positioning */
.footer-text {
    position: absolute;
    bottom: 2.6vh;
    left: 3.5vw;    /* Adjust to position it to the left */
    font-size: 2rem;
    color: black;
    display: flex;
    align-items: center;
    z-index: 10;

    border-radius: 26px;
    padding: 1.7px;
  }
  
  .website-text {
    font-family: "Cutive Mono", serif;
    font-weight: 800;
    font-style: bold;
  }
  
  .ronnie-name {
    font-family: "Monoton", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ronnie-first-letter {
    margin-left: .17rem;
    font-size: 2.2rem;
    font-family: "Monoton", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ronnie-name {
    font-family: "Badeen Display", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  /* Ensuring the footer text is not underlined */
  .footer-text a {
    text-decoration: none;
    color: inherit;
  }

  .footer-text a:hover::after {
    content: "";
    position: absolute;
    bottom: 0;       /* Position the arrow just below the text */
    left: 100%;      /* Position it to the right side of the text */
    transform: translateX(5px);  /* Adjust slightly to create space */
    width: 0;
    height: 0;
    border-top: 17px solid transparent;    /* Top side of the arrow */
    border-bottom: 17px solid transparent; /* Bottom side of the arrow */
    border-left: 17px solid rgb(255, 1, 192);         /* The actual arrow color (black here) */
  }


@media(max-width: 700px) {
    .footer-text {
        font-size: 1.1rem;
    }
}

  