/* Header Container */
.header-container {
    position: relative;
    top: 1vh;
    right: 4vw;
    z-index: 1000;
  }
  
  /* Normal Navbar for Larger Screens */
  .header-nav {
    display: flex;
    gap: 9vw;
  }
  
  .header-nav a, 
  .header-nav a {
    color: #171717;
    font-family: "Oswald", serif;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  .header-nav a:hover {
    border-top: 1px solid red;
    border-bottom: 2px solid coral;
    cursor: pointer;
  }
  
  /* Menu Icon for Mobile */
  .menu-icon {
    font-size: 3rem;
    cursor: pointer;
    font-weight: bold;
    color: #171717;
    transition: transform 0.3s ease;
  }
  
  .menu-icon:hover {
    color: #7A2021;
    transform: scale(1.1);
  }
  
  /* Dropdown Menu for Mobile */
  .dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 150px;
    text-align: center;
  }
  
  /* Menu Items */
  .dropdown-menu a {
    font-family: "Oswald", serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    color: #171717;
    padding: 10px;
    display: block;
    transition: all 0.3s ease;
  }
  
  .dropdown-menu a:hover {
    border-bottom: 2px solid coral;
  }
  
  /* When on Contact Page */
  .contact-page-nav a {
    color: white !important; 
  }
  
  /* Hide normal nav on mobile */
  @media (max-width: 700px) {
    .header-nav {
        display: none;
    }
  }
  