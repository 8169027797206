/* Viewport Size Display */
.viewport-size {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: monospace;
    z-index: 999;
    opacity: 0.8;
  }
  
  .landing-page {
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  html, body {
    overflow-x: hidden;
  }
  
  
  .image-container {
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
  
  .fullscreen-image {
      /* Full width: */
      width: 100vw;  
      /* Full height: */
      height: 100vh; 
      object-fit: cover; 
      object-position: center; 
      display: block;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
    }
    
    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100vw;
      min-height: 100vh;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 1) 10vw, rgba(255, 255, 255, 0.4) 25vw, rgba(255, 255, 255, 0) 30vw);
      /* Allows interaction with the image:+ */
      pointer-events: none; 
    }
  
    .logo {
      position: absolute;
      top: 2vh; 
      /* How far from the left: */
      left: 1vw;
      /* Scales based on screen width: */
      width: 30vw; 
      /* Prevents the logo from getting too big: */
      max-width: 500px; 
      height: auto; 
      /* Ensures it appears above everything else: */
      z-index: 10; 
      opacity: .68; /* makes it appear lighter */
    }
    
  
    .locations-container {
      position: absolute;
      top: 63vh;
      right: 2vw; 
      /* ensures centering: */
      transform: translateY(-50%); 
      display: flex;
      /* stacks text vertically: */
      flex-direction: column; 
       /* Aligns text to the right: */
      align-items: flex-end;
    }
    
    .image-text {
      font-size: 10vw; 
      color: #333;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      font-weight: bold;
      text-align: right;
      /* spacing between lines: */
      line-height: 1.1; 
      letter-spacing: 1.1vw;
      /* blends with the background: */
      mix-blend-mode: screen; 
      /* makes it appear lighter: */
      opacity: .4; 
    }
    
    .header-nav {
      position: absolute;
      top: .8vh;
      right: 4.4vw; 
      display: flex;
      /* space between each nav item: */
      gap: 9vw; 
    }
    
    .header-nav a {
      color: #171717; 
      font-family: "Oswald", serif;
      font-size: 2rem;
      font-weight: bold;
      /* no underline: */
      text-decoration: none; 
    }
    
    .header-nav a:hover {
      border-top: 1px solid red;
      border-bottom: 2px solid coral;
      cursor: pointer;
    }
  
    .company-name-container {
      position: absolute;
      top: 80vh;
      z-index: 10;
    }
  
    .company-name {
      font-size: 3rem;
      color: black;
      font-family: "Dancing Script", serif;
      font-weight: 2;
      margin-left: 6vw;
    }
  
    .business {
      font-family: "Bodoni Moda", serif;
      margin-left: 4vw;
    }

  
  .back-to-top {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    font-family: "Oswald", serif;
    font-weight: bold;
  }
  
  .back-to-top a {
    color: black;
    text-decoration: none;
  }
  
  .back-to-top a:hover {
    text-decoration: none;
    border-top: 3px solid #FF5349;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: tan;
    border-radius: 2px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: tan;
  }
  
  /* Header Container */
  .header-container {
    position: relative;
    top: 1vh;
    right: 4vw;
    z-index: 1000;
  }
  
  /* Normal Navbar for Larger Screens */
  .header-nav {
    display: flex;
    gap: 9vw;
  }
  
  .header-nav a, 
  .header-nav a {
    color: #171717;
    font-family: "Oswald", serif;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  .header-nav a:hover {
    border-top: 1px solid red;
    border-bottom: 2px solid coral;
    cursor: pointer;
  }
  
  /* Menu Icon for Mobile */
  .menu-icon {
    font-size: 3rem;
    cursor: pointer;
    font-weight: bold;
    color: #171717;
    transition: transform 0.3s ease;
    position: absolute;
    right: 2rem;
    background: transparent;
    border: none;
  }
  
  .menu-icon:hover {
    color: #7A2021;
    transform: scale(1.1);
  }
  
  /* Dropdown Menu for Mobile */
  .dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 150px;
    text-align: center;
  }
  
  /* Menu Items */
  .dropdown-menu a {
    font-family: "Oswald", serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    color: #171717;
    padding: 10px;
    display: block;
    transition: all 0.3s ease;
  }
  
  .dropdown-menu a:hover {
    border-bottom: 2px solid coral;
  }
  
  /* When on Contact Page */
  .contact-page-nav a {
    color: white !important; 
  }
  
  /* Special styling when on Contact or About page */
  .contact-menu-icon {
    color: white; 
  }

  /* location section */
.map-section {
  display: flex;
  justify-content: center; 
  align-items: center;     
  padding: 2rem 0;
  min-height: 500px;      
}

.map-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

  @media (max-width: 1300px) {
    .logo {
       top: 6.2vh;
    }
  }
  
  /* Hide normal nav on mobile */
  @media (max-width: 700px) {
    .header-nav {
        display: none;
    }
  }
  
  
    /* 📱 Mobile & Tablet (Max 700px) */
    @media (max-width: 720px) {
      .image-overlay {
        background: none;
      }
      .hero {
        height: 100vh;
        justify-content: flex-start;
      }
      .company-name {
        position: absolute;
        bottom: 4vh;
      }
    
      .business {
        position: absolute;
      }
      .locations-container {
        position: absolute;
        top: 44vh;
        width: 100vw;
      }
      .image-text {
        font-size: 5.3rem;
      }
      .company-name-container {
        position: absolute;
        top: 84vh;
        max-width: 100vw;
      }
    
      .company-name {
        width: 100vw;
        font-family: "Oswald", serif;
        font-weight: 400;
      }
    
      .business {
        max-width: 100vw;
      }

    
    }

