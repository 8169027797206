
.contact-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-container {
    width: 90%;
    max-width: 600px;
    background: #faeddb;
    opacity: 0.89;
    padding: 30px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-family: "Oswald", serif;
}


.contact-nav {
    text-align: left; 
    margin-bottom: 15px; 
}

h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input, textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    font-family: "Oswald", serif;
}

textarea {
    min-height: 100px;
}

button {
    background: black;
    color: white;
    font-family: "Bodoni Moda", serif;
    letter-spacing: .2rem;
    padding: 12px;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
}

button:hover {
    background: darkgray;
}

.form-status {
    font-weight: bold;
    color: red;
}
