/* Overlay to darken background */
.relocation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.relocation-icon {
    color: black; 
    filter: grayscale(100%);
}

/* Pop-up box */
.relocation-popup {
    position: relative;
    background: white;
    padding: 25px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-family: "Oswald", serif;
}

/* Close button */
.close-button {
    position: absolute; 
    top: 10px;          
    right: 15px;        
    border: none;
    font-size: 2rem;
    color: black;
    background: none;
    cursor: pointer;
}

.close-button:hover {
    color: rgb(236, 48, 48);
    font-size: 4.4rem;
    background: none;
}


/* Adjust for smaller screens */
@media (max-width: 768px) {
    .relocation-popup {
        width: 95%;
        max-width: 400px;
    }
}
