
.location-page {
    background-image: url('https://images.squarespace-cdn.com/content/v1/5d377b7d79859c0001ebed47/1569106168746-QLCLZ8TECR0NVZ42B3Q2/thatcherHotel_Rooms295.jpg?format=1000w');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Ensure Header overlays correctly */
.location-page > .header {
    z-index: 2;
    width: 100%;
}

/* Wrapper for the map with frame styling */
.map-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    padding-top: 5.3rem;
    padding-bottom: 0;
}

.map-frame {
    background-color: #faeddb;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); /* frame shadow */
    max-width: 90vw;
    width: 100%;
    height: 60vh;
    max-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles applied to the map canvas */
.map-frame > div {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25); /* map box shadow */
    width: 100%;
    height: 100%;
}


.map-overlay-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .custom-overlay-marker {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: auto;
    z-index: 5;
    pointer-events: none; /* So it doesn’t block map interaction */
  }
  
  /* Responsive resizing */
  @media (max-width: 768px) {
    .custom-overlay-marker {
      width: 30px;
    }
  }
  @media (max-width: 480px) {
    .custom-overlay-marker {
      width: 24px;
    }
  }
  
  .ghost-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.7rem; /* space between words */
    font-size: 4.4vw;
    font-weight: 700;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 4.8rem;
    color: transparent;
    -webkit-text-stroke: 1.5px #d8c9b3;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    mix-blend-mode: overlay;
    text-shadow:
      1px 1px 2px rgba(0, 0, 0, 0.15),
      -1px -1px 2px rgba(255, 255, 255, 0.2);
    z-index: 2;
    pointer-events: none;
  }
  
  /* Target the individual spans */
  .ghost-text span {
    display: inline-block;
  }
  
  /* At 1200px and below: two per line */
  @media (max-width: 1200px) {
    .ghost-text span {
      width: 44%;
      text-align: center;
      letter-spacing: normal;
    }
  }
  
  /* At 700px and below: one per line */
  @media (max-width: 700px) {
    .map-wrapper {
        margin-top: 3.5vh;
    }
  }
  
  
  